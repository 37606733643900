<template>
  <div>
    <el-card class='box-card'>
      <!--      筛选条件-->
      <el-row :gutter='15'>
        <el-col :span='24'>
          <el-form size='small' inline>
            <el-form-item>
              <el-select v-model='searchCondition.leader_id' filterable
                         placeholder='产品负责人' @focus='getLbLeaderLdOptions' @change='getList' clearable>
                <el-option :label='item.leader_name' :value='item.leader_id' v-for='(item,index) in searchLeaderOptions'
                           :key='index'>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-input v-model='searchCondition.title' placeholder='商品名称' clearable @change='getList'></el-input>
            </el-form-item>
            <el-form-item>
              <CategoryCascader  ref="cascades"  :identify='`product`' @cascaderChange='cascaderChange'/>
            </el-form-item>
            <el-form-item>
              <el-select v-model='searchCondition.confirm_status' style='width: 180px' placeholder='选择机制确认状态' clearable>
                <el-option :label='option._label' :value='option.value' v-for='(option,key) in confirmStatusItem'
                           :key='key'
                           :style='`text-align: center;border-radius: 3px;margin:1px;border: #7d8086 1px solid;color:#f2f2f2;background-color:${option.color_value}`'></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type='primary' icon='el-icon-search' @click='getList' :disabled='tableLoading'
                         size='mini' :loading='tableLoading'>搜索
              </el-button>
            </el-form-item>
            <el-form-item>
              <el-button size='mini' @click.stop.native='reset'>重置</el-button>
            </el-form-item>
            <el-form-item>
              <export-btn ref='refExportLink' type='primary'
                          size='mini' :lint-title='`导出表格${!isColSet?"(未设置列)":""}`'
                          :can-export="userPermissions.indexOf('lb_live_selection_export')>-1"
                          @export='exportExcel'></export-btn>

            </el-form-item>
            <el-form-item>
              <ExportLbProductPpt :id='id' :search-condition='pptSearchCond'
                                  size='mini'
                                  v-if="userPermissions.indexOf('lb_live_selection_export_ppt')>-1"></ExportLbProductPpt>
            </el-form-item>
            <el-form-item>
              <el-button icon='el-icon-setting' v-if='userPermissions.indexOf("lb_live_selection_set")>-1'
                         size='mini' @click='customColumnShow=true'>列设置
              </el-button>
            </el-form-item>
          </el-form>
        </el-col>

      </el-row>
      <!--  操作栏 -->
      <el-row :gutter='15' class='mb8'>
        <el-col :span='24'>
          <el-form size='small' inline>
            <el-form-item>
              <el-button type='primary' icon='el-icon-plus' @click='handleAdd'
                         size='mini' v-if='userPermissions.indexOf("lb_live_selection_add")>-1'>
                添加商品({{ dataList.length }})
              </el-button>
            </el-form-item>
            <el-form-item>
              <el-button icon='el-icon-delete' :disabled='multiple'
                         size='mini' plain type='danger' @click='batchDelProducts'
                         title='删除后需要通过初选选品重新添加' v-if='userPermissions.indexOf("lb_kol_selection_delete")>-1'>
                批量移除({{ ids.length }})
              </el-button>
            </el-form-item>
            <el-form-item>
              <el-button title='该红人的历史直播' @click="handleHistory('schedule')"
                         size='mini' icon='el-icon-refrigerator'
                         v-if='userPermissions.indexOf("lb_live_selection_history")>-1'>
                历史直播
              </el-button>
              <el-button title='该红人的历史选品会' @click="handleHistory('selection')"
                         size='mini' icon='el-icon-s-order'
                         v-if='userPermissions.indexOf("lb_live_selection_history")>-1'>
                历史选品会
              </el-button>

              <el-dropdown @click='handleAck(confirmStatusItem[StateActive].value)' @command='command'
                           title='修改机制' :disabled='multiple' split-button
                           style='margin-left: 10px' :type='confirmStatusItem[StateActive].type '
                           v-if='userPermissions.indexOf("lb_live_selection_edit")>-1'>
                <i :class='confirmStatusItem[StateActive].icon'></i> {{ confirmStatusItem[StateActive].label }}
                <el-dropdown-menu slot='dropdown'>
                  <el-dropdown-item :command='index' v-for='(item,index) in confirmStatusItem' :key='item.value'
                                    style='width: 120px'>
                    <i :class='item.icon'></i> {{ item.label }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <!--              <el-button icon='el-icon-s-order' title='机制确认' type='primary'-->
              <!--                         @click="handleConfirm()">-->
              <!--                -->
              <!--                -->
              <!--              </el-button>-->
            </el-form-item>
            <!--            <el-form-item>-->
            <!--              <el-button plain type='warning' icon='el-icon-remove' :disabled='multiple'-->
            <!--                         @click='cancelTagSelection' v-if='userPermissions.indexOf("lb_live_selection_delete")>-1'>-->
            <!--                批量移除-->
            <!--              </el-button>-->
            <!--            </el-form-item>-->

            <el-form-item>
              <el-button @click='handleEdit' size='mini'
                         :disabled='dataList.length===0' v-if='userPermissions.indexOf("lb_live_selection_edit")>-1'>
                修改机制
              </el-button>
            </el-form-item>
            <el-link type="primary" style="float: right;display: inline-block" size='mini'
                     @click="handlePreview">
              {{ isPreview ? '完整模式' : '预览模式' }}
            </el-link>

          </el-form>
        </el-col>
      </el-row>

      <!-- 直播定品表-->
      <div>
        <div class='default-table' v-if="!isPreview">
          <MSetTable ref='setTableRef'
                     :offset='offset'
                     :tableData='dataList'
                     :columns='columns'
                     @row-contextmenu='openMenu' row-key='id'
                     :loading='tableLoading'
                     :cell-class-name='addCellClass'
                     @selection-change='handleSelectionChange'
                     @editCellVal='editCellVal'
                     @row-dblclick='dblclickRow'
                     rowId='id'
                     :showIndex='true'
                     :border='true'>
            <el-table-column width='55' align='center' slot='first-column'>
              <template>
                <el-tag class='move-column' effect='plain' style='cursor: move;'>
                  <span class='el-icon-rank' style='width: 1em; height: 1em;'/></el-tag>
              </template>
            </el-table-column>

            <el-table-column type='selection' width='55' align='center' slot='second-column'></el-table-column>
          </MSetTable>
          <el-divider content-position='left'>个性化表格：1）支持行拖拽排序和列设置 2）双击单元格复制文本到剪切板
          </el-divider>
        </div>
        <preview-table v-if="isPreview" :tableData='dataList' :showIndex="true" :columns='columns'>
        </preview-table>
      </div>
    </el-card>

    <!--    产品表 -->
    <DialogSelectProduct :visible.sync='open' :lb-id='id' @close='getList'></DialogSelectProduct>

    <!--    修改机制-->
    <EditLbProduct :visible.sync='dialogEditFormVisible' :ids='ids'
                   v-if='userPermissions.indexOf("lb_live_selection_edit")>-1' @close='getList' />

    <!--    列设置-->
    <MColumnSetting :visible.sync='customColumnShow' ref='columnSetting' @userChange='columnSettingChange'
                    :column='userColumns' :table-name='tableName' @back='columnSettingBack' @save='columnSettingSave'
                    :editable-cols='editableCols' />

    <!--    历史排期-->
    <HistoricalSchedule :historicalType='HistoricalType' :visible.sync='historicalDialog' :lb-id='id'
                        @close='getList'></HistoricalSchedule>
    <!--    机制确认-->

    <!--    <el-dialog-->
    <!--        :before-close="handleClose"-->
    <!--        :visible.sync="buttonsVisible"-->
    <!--        title="机制确认"-->
    <!--        width="20%">-->
    <!--      <el-button-group>-->
    <!--        <el-button  @click="handleAck('1')"> 未确认</el-button>-->
    <!--        <el-button  @click="handleAck('2')">已确认</el-button>-->
    <!--        <el-button  @click="handleAck('3')">已确认已修改</el-button>-->
    <!--        <el-button  @click="handleAck('4')">二次确认</el-button>-->

    <!--      </el-button-group>-->
    <!--    </el-dialog>-->
    <!--    右键菜单-->
    <Contextmenu :isLog='false' :menu-data='ContextMenuData' :menu-list='menuList' :id='currentId'
                 @change='handleCmd'/>
    <el-dialog center
               :visible.sync="leaderVisible"
               width="250px">
      <div style="width: 220px;margin: 0 auto">
        <el-link title='产品负责人' @click='editLeader=!editLeader' v-if='editRow.leader_id'
                 style='margin-right: 10px;font-size: 20px'>
          当前负责人：{{ editRow.leader_name }}
        </el-link>

        <UserSelect v-if='editLeader||!editRow.leader_id' :keyword='editRow.leader_name'
                    style="margin-top: 20px;display: inline-block" @selected='handleUserSelect'/>
        <el-button title='修改负责人' type='text' @click='editLeader=!editLeader'
                   style="font-size: 14px;display: inline-block" v-if='editRow.leader_id'>
          <i class="el-icon-edit-outline" ></i>
        </el-button>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="handleCancel">取 消</el-button>
    <el-button type="primary" @click="handleSubmit">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import ArtistSearch from '@/components/artist/ArtistSearch'
import { mapGetters } from 'vuex'
import ExportBtn from '@/components/export/ExportBtn'
import ProductImage from '../product/ProductImage'
import LogList from '@/pages/lb/components/LogList'
import ProductTable from '@/pages/lb/product_selection/ProductTable'
import ExportPpt from '@/pages/lb/components/ExportPpt'
import CategoryCascader from '@/pages/category/components/CategoryCascader'
import ExportProductPpt from '@/pages/lb/components/ExportProductPpt'
import ExportLbProductPpt from '@/pages/lb/selection_ack/ExportLbProductPpt'
import Sortable from 'sortablejs'
import DialogSelectProduct from '@/pages/lb/live/DialogSelectProduct'
import EditableCell from '@/components/EditableCell'
import EditLbProduct from './EditLbProduct'
import MColumnSetting from '@/components/common/MColumnSetting'
import HistoricalSchedule from '@/pages/lb/live/HistoricalSchedule.vue'
import MSetTable from '@/components/common/MSetTable'
import Contextmenu from '@/pages/lb/product/Contextmenu'
import UserSelect from '@/pages/lb/components/UserSelect'
import PreviewTable from '@/pages/lb/live/PreviewTable'


export default {
  //直播选品
  name: 'SelectionLive',
  components: {
    MSetTable,
    MColumnSetting,
    EditLbProduct,
    EditableCell,
    DialogSelectProduct,
    ExportLbProductPpt,
    ExportProductPpt,
    ExportPpt,
    ProductTable,
    LogList,
    ProductImage,
    ExportBtn,
    ArtistSearch,
    CategoryCascader,
    Sortable,
    HistoricalSchedule,
    Contextmenu,
    UserSelect,
    PreviewTable
  },
  props: {
    id: {
      type: [Number, String]
    },
    showHeader: {
      type: Boolean,
      default() {
        return true
      }
    }
  },
  computed: {
    ...mapGetters(['userPermissions', 'userInfo']),
    cascaderProps() {
      return { value: 'id', label: 'display_name', checkStrictly: true, multiple: false }
    },
    canOriginal() {
      //可完成初选的前提：自己负责的排期或者拥有修改负责人（发起人）权限
      return this.userPermissions.indexOf('lb_schedule_original_btn') > -1 && (this.userPermissions.indexOf('lb_schedule_edit_leader') > -1 || this.info.leader_id === this.userInfo.id) && this.info.progress_status === 2
    },
    progressStatusMap() {
      let map = {}
      this.progressStatusOptions.forEach((item) => {
        map[item.value] = item.label
      })
      return map
    },
    progressStatusColorMap() {
      let map = {}
      this.progressStatusOptions.forEach((item) => {
        map[item.value] = item.color_value
      })
      return map
    },
    exportTitle() {
      return `导出表格`
    },
    dialogTitle() {
      return `${this.$utils.parseTime(this.info.start_at, '{y}-{m}-{d}')} ${this.info.nickname}
                                ${this.info.platform_name || this.info.platform_code} ${this.info.theme}`
    },

    searchTitleOptions() {
      let options = []
      this.dataList.forEach((item) => {
        if (options.indexOf(item.title) === -1)
          options.push(item.title)
      })
      return options
    },
    categoryStatistics() {
      let sData = []
      this.dataList.forEach((item) => {
        let index = sData.findIndex(value => value.category_id === item.category_id)
        if (index === -1)
          if (index === -1)
            sData.push({ category_id: item.category_id, category: item.category, total: 1 })
          else {
            sData[index]['total'] += 1
          }
      })
      return sData
    },
    //PPT导出的筛选条件
    pptSearchCond() {
      let cond = this.handleSearchCondition()
      if (this.ids && this.ids.length > 0)
        Object.assign(cond, { ids: this.ids })
      return cond
    }
  },
  data() {
    return {
      customColumnShow: false,//显示设置
      tableName: 'lb_live_selection',
      open: false,
      dialogEditFormVisible: false,
      showForm: true,
      showNotify: false,
      searchCondition: { lb_id: null, leader_id: null, title: null, state: null, is_ack: null, category_ids: null },
      rules: {
        nickname: { required: true, message: '请选择红人（KOL）', trigger: 'change' },
        id: { required: true, message: '请选择排期', trigger: 'change' },
        ad_space_fee: { required: true, message: '请输入坑位费', trigger: 'change' }
      },
      searchLeader: '',
      searchProductTitle: '',
      addPreCard: false,
      dataList: [],
      tableLoading: false,
      offset: 0,
      tipList: [
        '1、流程：确认初选选品 =》初选完成 =》确认完成选品 =》锁定表格',
        '2、锁定表格后才可下载表格和下载产品手卡',
        '3、产品手卡下载方式：1）产品手卡（单）：每个已确认选品单独导出为产品手卡PPT文件，即导出多个PPT文件，这个情况下浏览器可能会询问是否允许，设置允许即可；2）产品手卡（多）：全部已确认选品的产品手卡导出为一个PPT文件'
      ],
      showSaveBtn: false,
      info: {},
      progressStatusOptions: [
        { label: '新创建', value: 1, color_value: '#FFFFFF', class_name: 'status-new' },
        { label: '已发需求', value: 2, color_value: '#67C23A', class_name: 'status-launch' },
        { label: '初选完成', value: 3, color_value: '#409EFF', class_name: 'status-original' },
        { label: '确认完成', value: 4, color_value: '#E6A23C', class_name: 'status-ack' },
        { label: '锁定表格', value: 5, color_value: '#F56C6C', class_name: 'status-lock' }
      ],
      ackOptions: [
        { label: '确认', value: 'Y', color_value: '#67C23A' },
        { label: '待确认', value: 'N', color_value: '#F56C6C' }
      ],
      stateOptions: [
        { label: '上播', value: 1, color_value: '#909399', class_name: 'status-new' },
        { label: '不上播', value: 2, color_value: '#67C23A', class_name: 'status-launch' },
        { label: '红人试用', value: 3, color_value: '#409EFF', class_name: 'status-original' },
        { label: '团队试用', value: 4, color_value: '#E6A23C', class_name: 'status-ack' }
      ],
      loading: false,
      awaitDataList: [],
      // 选中数组(选品ID集合)
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      cellMode: 'form'//'form,
      ,
      selectedList: [],
      searchLeaderOptions: [],
      newIndexList: [],
      loadingStatus: false,
      orderSort: { id: 'desc' },
      editableCols: ['order', 'opt_remark', 'contract_progress'],
      //用户自定义列
      userColumns: [
        // { hide: false, prop: 'id', label: '序号', width: 80, sortable: false, fixed: false ,type:'index'},
        { hide: true, prop: 'id', label: 'ID', width: 80, sortable: false, fixed: false },
        { hide: false, prop: 'opt_remark', label: '运营备注', width: 120, sortable: false, fixed: false, editable: true },
        { hide: false, prop: 'product_updated_at', label: '产品最后更新', width: 140, sortable: true, fixed: false },
        // { hide: false, prop: 'record_at', label: '产品录入时间', width: 140, sortable: false, fixed: false },
        { hide: false, prop: '_is_sec_kill', label: '是否秒杀品', width: 100, sortable: false, fixed: false },
        { hide: false, prop: 'cover_url', label: '产品图', width: 80, sortable: false, fixed: false, type: 'image' },
        { hide: false, prop: '_confirm_status', label: '机制确认', width: 160, sortable: false, fixed: false },
        { hide: false, prop: 'title',  label: '产品名称',width: 160,  sortable: false,   fixed: false,  header_align: 'center',align: 'left'  },
        { hide: false, prop: 'category', label: '类目', width: 100, sortable: true, fixed: false },
        { hide: false, prop: 'spec_name', label: '规格', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'used_season', label: '季节性', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'spec_color', label: '色号', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'platform_names', label: '可播平台', width: 120, sortable: false, fixed: false },
        // { hide: false, prop: 'mode_alias', label: '合作方式', width: 100, sortable: false, fixed: false },
        { hide: false, prop: 'price', label: '日常价', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'lb_price', label: '直播价', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'gift', label: '赠品', width: 220, sortable: false, fixed: false },
        // { hide: false, prop: 'online_cmn', label: '线上佣金（%）', width: 120, sortable: false, fixed: false },
        // { hide: false, prop: 'offline_cmn', label: '线下佣金（%）', width: 120, sortable: false, fixed: false },
        // { hide: false, prop: 'ad_space_fee', label: '坑位费', width: 120, sortable: false, fixed: false },
        // { hide: false, prop: 'mode_alias', label: '合作方式', width: 100, sortable: false, fixed: false },
        { hide: false, prop: 'fee_commission', label: '费用佣金', width: 160, sortable: false, fixed: false },
        { hide: false, prop: 'other_activity', label: '是否统一机制（如有特殊请备注）', width: 220, sortable: false, fixed: false },
        { hide: false, prop: 'support_situation', label: '保价情况', width: 130, sortable: false, fixed: false },
        { hide: false, prop: 'promo_remark', label: '是否大促同机制（如不是请标注大促机制）', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'brand_support', label: '品牌支持', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'contract_progress', label: '合同进度', width: 120, sortable: false, fixed: false,editable: true },
        { hide: false, prop: 'leader_name', label: '负责人',  width: 80,sortable: false, fixed: false, icon: 'el-icon-mouse', editDialog: true },
        { hide: false, prop: 'order', label: '排序值', width: 110, sortable: false, fixed: false, editable: true },
        { hide: true, prop: 'created_at', label: '选品时间', width: 140, sortable: false, fixed: false },
        { hide: true, prop: 'updated_at', label: '修改时间', width: 140, sortable: false, fixed: false }
      ],
      columns: [],
      historicalDialog: false,
      isColSet: false,
      HistoricalType: '',
      confirmStatusItem: [
        {
          value: '1',
          icon: 'el-icon-close',
          label: '未确认',
          _label: '【未确认】待招商确认',
          type: 'info',
          color_value: '#909399'
        },
        {
          value: '2',
          icon: 'el-icon-circle-check',
          label: '招商确认',
          _label: '【招商已确认】待运营确认',
          type: 'success',
          color_value: '#E6A23C'
        },
        // { value: '3', icon: 'el-icon-folder-checked', label: '已确认已修改', type: 'warning', color_value: '#E6A23C' },
        {
          value: '4',
          icon: 'el-icon-s-check',
          label: '运营确认',
          _label: '【运营已确认】',
          type: 'success',
          color_value: '#67C23A'
        }
      ],
      handleState: [],
      StateActive: 0,
      menuList: {
        edit: false,
        log: false,
        del: false,
        copy: false
      },
      currentId: '',
      currentRow: {},
      ContextMenuData: {
        visible: false,
        top: 0,
        left: 0
      },
      leaderVisible: false,
      editLeader: false,
      editRow: {},
      isPreview: false

    }
  },
  methods: {

    getDefaultColumns() {
      if (localStorage.getItem(`table-column:${this.tableName}`)) {
        const tableSet = JSON.parse(localStorage.getItem(`table-column:${this.tableName}`))
        let userColumn = tableSet.user_columns
        this.columns = [...userColumn]
        this.isColSet = true
      } else {
        this.isColSet = false
        this.columns = [...this.userColumns]
      }
    },
    columnSettingChange(userColumn) {
      //设置列-修改表显示
      // this.customColumnShow=false
      // console.log('e', userColumn)
      this.columns = [...userColumn]
      this.isColSet = true
      // console.log('after:', this.columns)

    },
    // eslint-disable-next-line no-unused-vars
    columnSettingSave(userColumn) {
      this.customColumnShow = false
      this.isColSet = true
      // console.log('save', userColumn)
    },
    // eslint-disable-next-line no-unused-vars
    columnSettingBack(userColumn) {
      // console.log(userColumn)
      // this.customColumnShow = false
      this.isColSet = false
      // console.log('back', userColumn)
      this.getDefaultColumns()
    },
    // eslint-disable-next-line no-unused-vars
    dblclickRow(row, column, event) {
      event.preventDefault()
      if (column.property === 'leader_name') {
        this.editRow = row
        this.leaderVisible = true
      } else {
        //复制列数据
        const oInput = document.createElement('textarea')
        oInput.value = row[column.property]
        document.body.appendChild(oInput)
        oInput.select() // 选择对象;
        document.execCommand('Copy') // 执行浏览器复制命令
        this.$message({ message: '复制成功', type: 'success' })
        oInput.remove()
      }
    },
    async handleSubmit() {
      await this.editCellVal(this.editRow, 'leader_id')
      await this.editCellVal(this.editRow, 'leader_name')
      this.leaderVisible = false
    },
    handleCancel(){
      this.getList()
      this.leaderVisible=false
    },
    // 处理搜索结果的选中
    handleUserSelect(item) {
      this.editRow.leader_id = item.value || ''
      this.editRow.leader_name = item.label || ''
      this.editLeader=false
    },
    // eslint-disable-next-line no-unused-vars
    async editCellVal(row, field) {
      if (row.id && field) {
        await this.$api.singleSaveLbScheduleProduct(row.id, field, row[field])
      } else {
        this.$notify.warning('参数错误，无法提交！！！')
      }
    },
    showKolSelectionMeeting() {
      this.$notify.info('红人选品会')
    },
    changeTableSort({ prop, order }) {
      let orderSort = {}
      if (order) {
        orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
      }
      this.orderSort = orderSort
      // this.getList()
    },

    // eslint-disable-next-line no-unused-vars
    addCellClass({ row, column }) {
      if (column.property === 'title') {
        let cl = 'cell-title'
        if (row.is_new === 'Y') {
          cl += ' new-tag'
        }
        return cl
      }
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },

    async getSelect() {
      let { list } = await this.$api.getCategoryListByIdentify(`mode-category`)
      this.selectedList = list
    },
    async init() {
      // await this.getInfo()
      await this.getList()
      await this.getLbLeaderLdOptions()
    },

    async getLbLeaderLdOptions(force) {
      //获取负责人下拉信息
      if (this.searchLeaderOptions.length === 0 || force) {
        let { options } = await this.$api.getLbLeaderLdOptions({ lb_id: this.id })
        this.searchLeaderOptions = options
      }
    },
    async getInfo() {
      if (this.id) {
        //获取当前排期基本信息
        let { info } = await this.$api.getLbScheduleInfo(this.id)
        this.info = { ...info }
      }
    },
    reset() {
      this.searchCondition = {}
      this.searchCondition = {}
      this.$refs.cascades.clearFun();
      this.getList()
    },
    // 级联菜单组件change事件
    cascaderChange(v) {
      this.searchCondition.category_id = null
      this.searchCondition.category_ids = null
      if (v.length) {
        this.searchCondition.category_id = v[(v.length - 1)]
        this.searchCondition.category_ids = v
      }
      this.getList()
    },
    async getList() {
      this.dataList = []
      this.ids = []
      this.tableLoading = true
      //获取当前全部选品
      let params = this.handleSearchCondition()
      let { list } = await this.$api.getLbScheduleAckProducts(params)
      this.$nextTick(() => {
        this.dataList = [...list]
        //收集ID
        this.newIndexList = []
        this.dataList.forEach(item => {
          this.newIndexList.push(item.id)
        })

        document.body.addEventListener('drop', (event) => {
          event.preventDefault()
          event.stopPropagation()
        }, false)

        this.initSortableList()
      })
      setTimeout(() => {
        this.tableLoading = false
      }, 500)
    },
    async delRow(row) {
      let index = this.dataList.findIndex(value => value.id === row.id)
      if (index !== -1) {
        this.dataList.splice(index, 1)
      }
      this.$emit('del', row)
    },
    async delProducts(row) {
      if (row.id) {
        this.$confirm(`确定删除选品(${row.title})?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          let info = await this.$api.delLbScheduleProducts(row.id)
          if (info) {
            this.$message.success('删除成功')
            await this.delRow(row)
          }
        }).catch(() => {
          this.$message.info('已取消删除')
          // this.close()
          return false
        })
      } else {
        await this.delRow(row)
      }
    },
    //导出排期选品
    async exportExcel() {
      if (this.isColSet) {
        //若列已设置，则直接下载
        try {
          let name = `直播定品`
          let searchCondition = this.handleSearchCondition()
          if (this.ids && this.ids.length > 0) {
            Object.assign(searchCondition, { ids: this.ids })
          }
          Object.assign(searchCondition, { table_name: this.tableName })
          let response = await this.$api.exportLbScheduleSelection(searchCondition)
          this.$refs.refExportLink.exportSaveXlsx(response, name)
        } catch (e) {
          this.$message.warning('导出异常，请联系管理员')
        }
      } else {
        //hideLoading
        if (this.$refs.refExportLink)
          this.$refs.refExportLink.hideLoading()
        this.$confirm(`导出表格的列未设置，先设置列?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          this.customColumnShow = true
        }).catch(() => {
          this.$message.info('已取消')
          // this.close()
          return false
        })
      }
    },
    hideTotalDiv() {
      this.showForm = false
    },
    showProducts() {
      this.addPreCard = true

    },
    async handleAdd() {
      this.open = true
    },
    //修改机制
    handleEdit() {
      if (this.ids.length === 0) {
        this.ids = this.dataList.map((i) => {
          return i.id
        })
      }
      this.dialogEditFormVisible = true
    },
    handleHistory(value) {
      this.HistoricalType = value
      this.historicalDialog = true

    },
    handleSearchCondition() {
      let cond = {}

      if (this.searchCondition.category_ids) {
        cond['category_ids'] = this.searchCondition.category_ids
      }
      if (this.searchCondition.title) {
        cond['title'] = this.searchCondition.title
      }
      if (this.searchCondition.leader_id) {
        cond['leader_id'] = this.searchCondition.leader_id
      }
      if (this.searchCondition.confirm_status) {
        cond['confirm_status'] = this.searchCondition.confirm_status
      }
      // if (this.searchCondition.state) {
      //   cond['state'] = this.searchCondition.state
      // }
      //归属排期
      cond['lb_id'] = this.id

      return cond
    },
    async handleOriginal() {
      this.$confirm(`此操作将【完成初选】，是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await this.$api.originalLbSchedule(this.id)
        await this.init()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    async handleLock() {
      let total = this.dataList.length
      this.$confirm(`已确认选品：${total}个，锁定表格后可下载，是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.loading = true
        await this.$api.lockLbSchedule(this.id)
        setTimeout(() => {
          this.loading = false
        }, 500)
        await this.init()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    async tagSelection() {
      //选择的标记为Y
      let info = await this.$api.tagLbScheduleProducts(this.ids)
      if (info === 'ok') {
        this.msgSuccess('操作成功')
        // 刷新待选列表
        setTimeout(() => {
          this.getList()
        }, 500)

      }
    },
    async cancelTagSelection() {
      //选择的标记为Y
      let info = await this.$api.cancelTagLbScheduleProducts(this.ids)
      if (info === 'ok') {
        this.msgSuccess('操作成功')
        // 刷新待选列表
        setTimeout(() => {
          this.getList()
        }, 500)
      }
    },
    cascaderModeChange(row) {
      let modeArr = row.modeArr
      let mode = modeArr[modeArr.length - 1]
      let data = this.selectedList.find(value => value.id === mode)
      if (data)
        row.mode_alias = data.display_name || ''

      row.mode = mode
    },
    //绑定拖拽事件
    initSortableList() {
      document.body.addEventListener('drop', (event) => {
        event.preventDefault()
        event.stopPropagation()
      }, false)

      if (this.$refs.setTableRef) {
        let el = this.$refs.setTableRef.$el.querySelector('.el-table__body-wrapper>.el-table__body >tbody')
        //设置配置
        // let _this = this
        Sortable.create(el, {
          animation: 150,
          sort: true,
          // draggable: '.el-table__row', // 设置可拖拽行的类名(el-table自带的类名)
          handle: '.move-column',
          onEnd: async evt => {
            const oldIndex = evt.oldIndex
            const newIndex = evt.newIndex
            let obj = Object.values(evt.item.attributes).find(value => value.nodeName == 'data-rowid')
            // 数据类型:string
            let dataId = obj ? obj['value'] : 0
            await this.dragSortSubmit(newIndex, oldIndex,dataId)

          }
        })
      }
    },
    // eslint-disable-next-line no-unused-vars
    async dragSortSubmit(newIndex, oldIndex, dataId) {
      // let realIndex = this.dataList.findIndex(value => value.id == dataId)
      // const offset = realIndex - oldIndex
      // // console.log(`偏移量：${offset} (${oldIndex} -> ${newIndex})`)
      // //【校准后】调整偏移量之后
      // newIndex = newIndex + offset
      // oldIndex = oldIndex + offset
      // console.log(`[校对后]偏移量：${offset} (${oldIndex} -> ${newIndex})`)
      //获取排序index 1:4
      let dragId = this.dataList[oldIndex]['id']
      let dragOrder = this.dataList[newIndex]['order']
      // console.log('drag', dragId, dragOrder)
      let postData = [
        { id: dragId, order: dragOrder }
      ]
      let startIndex = newIndex > oldIndex ? oldIndex : newIndex
      let endIndex = newIndex > oldIndex ? newIndex : oldIndex

      if (newIndex > oldIndex) {
        // console.log(`向下拖拽：${oldIndex}->${newIndex}`)
        for (let i = startIndex + 1; i <= endIndex; i++) {
          let preRow = this.dataList[i - 1]
          let orgRow = this.dataList[i]
          // console.log(`drag ${i}`, { id: orgRow.id, order: preRow.order })
          postData.push({ id: orgRow.id, order: preRow.order })

        }
      } else {
        // console.log(`向上拖拽：${oldIndex}->${newIndex}`)
        for (let i = startIndex; i < endIndex; i++) {
          let preRow = this.dataList[i + 1]
          let orgRow = this.dataList[i]
          // console.log(`drag ${i}`, { id: orgRow.id, order: preRow.order })
          postData.push({ id: orgRow.id, order: preRow.order })
        }
      }

      //【数据】前端数据调换
      let currRow = this.dataList.splice(oldIndex, 1)[0]
      this.dataList.splice(newIndex, 0, currRow)
      // this.$notify.info(`您将 第${oldIndex + 1}行 拖拽至 第${newIndex + 1}行`)
      await this.$api.saveScheduleOrder({ ids: postData })
      postData.forEach((item) => {
        let index = this.dataList.findIndex(i => i.id == item.id)
        this.dataList[index].order = item.order
      })

      // setTimeout(async () => {
      //   await this.getList()
      // }, 500)
      // await this.$api.saveScheduleOrder({ ids: this.newIndexList })
    },
    async batchDelProducts() {
      if (this.ids) {
        this.$confirm(`确定删除勾选的选品(${this.ids.length}个)?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          this.delRows(this.ids)
        }).catch(() => {
          this.$message.info('已取消删除')
          // this.close()
          return false
        })
      }
    },
    async delRows(ids) {
      if (ids.length > 0) {
        let id = ids.splice(0, 1)
        await this.delProduct(id[0])
        if (ids.length > 0) {
          setTimeout(() => {
            this.delRows(ids)
          }, 100)
        } else {
          //刷新列表
          await this.getList()
        }
      }
    },
    async delProduct(id) {
      let info = await this.$api.delLbScheduleProducts(id)
      if (info) {
        this.$notify.success('删除成功')
      }
    },
    command(val) {
      this.StateActive = val
      this.handleAck(this.confirmStatusItem[this.StateActive].value)
    },
    async handleAck(val) {
      // console.log(val)
      let id = await this.$api.changeConfirmScheduleProducts(this.ids, val)
      if (id) {
        this.$message.success('修改成功')
        await this.getList()
      }
    },
    //右键菜单关闭
    closeMenu(e) {
      //未点击在 菜单上，则关闭
      if (e.target.className.indexOf('contextmenu__item') === -1) {
        this.menuData.visible = false
        document.removeEventListener('click', this.closeMenu)
      }
    }
    ,
    openMenu(row, column, e) {
      this.currentRow = row
      // this.$notify.info(`右键菜单:${e.clientY}`)
      e.preventDefault()//阻止元素发生默认的行为
      // const offsetLeft = this.$el.getBoundingClientRect().left; // container margin left
      // const offsetTop = 0// this.$el.getBoundingClientRect().top; // container margin top
      this.menuList.del = this.userPermissions.indexOf('lb_live_selection_delete') > -1

      this.ContextMenuData = {
        visible: true,
        left: e.pageX,//- offsetLeft
        top: e.pageY// - offsetTop
      }
      this.currentId = row.id
      document.addEventListener('click', this.closeContextMenuData) // 给整个document新增监听鼠标事件，点击任何位置执行foo方法
    },
    closeContextMenuData() {
      this.ContextMenuData.visible = false
      document.removeEventListener('click', this.closeContextMenuData)
    },
    handleCmd(cmd) {
      //1：修改 2：复制 3：删除
      switch (cmd) {
        case '1':
          this.ids = [this.currentId]
          this.handleEdit()
          break
        case '3':
          this.$confirm(`确定删除选品(${this.currentRow.title})?`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
            await this.delProduct(this.currentRow.id)
            await this.getList()
          })
          break
      }
    },
    isEditableCols() {
      let newEdit = ['spec_name', 'spec_color', 'price', 'lb_price', 'gift', 'promo_remark', 'other_activity', 'ad_space_fee', 'online_cmn', 'offline_cmn', 'brand_support', 'stock_info', 'sample_info', 'reason', 'fee_commission', 'support_situation', 'remark']

      if (this.userPermissions.indexOf('lb_live_selection_edit') > -1) {
        this.editableCols = [...this.editableCols, ...newEdit]
        this.menuList.edit = true
      }
    },
    handlePreview() {
      this.isPreview = !this.isPreview
      localStorage.setItem('lbIsPreview', this.isPreview)

    }
  },
  async mounted() {
    let isPreview = localStorage.getItem(`lbIsPreview`)
    this.isPreview = isPreview === 'true'
    await this.init()
    document.body.addEventListener('drop', (event) => {
      event.preventDefault()
      event.stopPropagation()
    }, false)

    // this.initSortableList()
    this.isEditableCols()
  },
  created() {
    this.searchCondition.lb_id = this.id
    this.getDefaultColumns()
  }
}
</script>

<style scoped>
.total-card {
  background: #0C0C0C;
  opacity: 0.8;
  padding: 20px;
}

.total-form {
  /*background: #f2f2f2;*/
  padding: 20px 20px;
  border-radius: 5px;
}


</style>
<style>
.cell-title {
  font-size: 1.1em;
  font-weight: bold;
  margin-left: 5px;
}


</style>
